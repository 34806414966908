import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import CheckLogin from './CheckLogin'
import { useNavigate } from 'react-router-dom'

export default function Sidebar() {
const corporate_customer=sessionStorage.getItem('corporate_customer')

const navigate=useNavigate()
const get_userid=sessionStorage.getItem('userid')
if(get_userid =="" || get_userid =="0"){
  navigate('/login');
}

  return (
    <div id="admin-sidebar" className="col-md-3 p-x-0 p-y-3 sidebar">
       <CheckLogin />
    <ul className="sidenav admin-sidenav list-unstyled">
      <li><NavLink to="/my-account/my-parcels">MY PARCELS</NavLink></li>
      <li><NavLink to="/my-account/quick-quote">QUOTE PACKAGE</NavLink></li>
      <li><NavLink to="/my-account/document-quote">QUOTE DOCUMENT</NavLink></li>
      <li><NavLink to="/my-account/domestic-quote">QUOTE DOMESTIC</NavLink></li>
      {corporate_customer && corporate_customer>0?(
        <li><NavLink to="/my-account/bulk-upload/">BULK UPLOAD</NavLink></li>  
      ):null}  
      <li><NavLink to="/track">TRACK</NavLink></li>
      <li><NavLink to="/my-account/my-wallet/">MY WALLET</NavLink></li>  
      {/* <li><NavLink to="/my-account/my-subscriptions/">MY SUBSCRIPTIONS</NavLink></li>                   */}
      <li><NavLink to="/my-account/address-book">ADDRESS BOOK</NavLink></li>                  
   
      <li><NavLink to="/my-account/settings">SETTINGS</NavLink></li>      
    </ul>

  </div> 
  )
}
